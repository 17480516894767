<template>
  <div class="videoWritingIndex">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "videoWritingIndex",
};
</script>
